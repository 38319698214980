.form-control {
  border-color: $input-border;
  color: $gray-900;
background-color: $form-control-bg;
  font-size: $font-size-14;
  font-weight: $font-weight-normal;
  line-height: 1.6;
  border-radius: 5px;
  padding: 0.5rem 0.625rem;
  height: 38px;
  transition: all 0.5s;
  &:focus {
    box-shadow: none;
    border-color: $input-border;
  }
}
textarea {
  &.form-control {
    min-height: 38px;
    height: auto;
  }
  resize: none;
}
.form-control[type="file"] {
  padding: 0.3rem 0.5rem;
  &.form-control-sm {
    padding: 0.25rem 0.5rem !important;
  }
  &.form-control-lg {
    padding: 0.5rem 1rem !important;
  }
}
.form-text {
  color: $text-muted;
}
.form-control-light {
  background-color: $light;
  border: 0;

  &:focus {
    background-color: $light;
  }
}
.form-control-primary {
  background-color: rgba($primary, 0.1);
  border: 0;
  color: $primary;

  &:focus {
    background-color: rgba($primary, 0.1);
    color: $primary;
  }
}
.form-input-color {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: $border-radius;
  overflow: hidden;
  padding: 0;
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: $text-muted;

}
.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  background-color: transparent;
}
.form-select {
  background-color: $form-control-bg;
  border: 1px solid $input-border;
  color: $text-color;
  font-size: 12px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-size: 1.25rem;
  line-height: 2;
  option {
    background-color: $white;
    padding: 0.35rem 0.75rem;
    border-radius: 0.25rem;
    margin-block-end: 0.25rem;
    &:last-child {
      margin-block-end: 0;
    }
  }
  option:checked {
    background-color: rgba(var($primary), 0.2);
    color: $primary;
  }
  option:select {
    background-color: rgba(var($primary), 0.2);
    color: $primary;
  }
  option:hover {
    background-color: rgba(var($primary), 0.2);
    color: $primary;
  }
  option:focus {
    background-color: rgba(var($primary), 0.2);
    color: $primary;
  }
}
.form-check-input {
  width: 0.9rem;
  height: 0.9rem;
  background-color: $white;
  border: 1px solid $input-border;
  &:checked {
    background-color: $primary;
    border-color: $primary;
  }
  &.form-checked-outline {
    &:checked {
      background-color: transparent;
      border-color: $primary;
    }
  }
  &.form-checked-secondary {
    &:checked {
      background-color: $secondary;
      border-color: $secondary;
    }
  }
  &.form-checked-warning {
    &:checked {
      background-color: $warning;
      border-color: $warning;
    }
  }
  &.form-checked-info {
    &:checked {
      background-color: $info;
      border-color: $info;
    }
  }
  &.form-checked-success {
    &:checked {
      background-color: $success;
      border-color: $success;
    }
  }
  &.form-checked-danger {
    &:checked {
      background-color: $danger;
      border-color: $danger;
    }
  }
  &.form-checked-light {
    &:checked {
      background-color: $light;
      border-color: $light;
    }
  }
  &.form-checked-dark {
    &:checked {
      background-color: $dark;
      border-color: $dark;
    }
  }
  &:focus {
    box-shadow: none;
  }
}
.form-checked-outline:checked[type="checkbox"] {
  background-image: none;
  position: relative;
  background-color: transparent;

  &:before {
    content: "\F633";
    font-family: bootstrap-icons !important;
    position: absolute;
    color: $primary;
    width: 0.625rem;
    height: 0.625rem;
    inset-block-start: -1px;
    inset-inline-start: 0px;
    font-size: 0.688rem;
  }

  &.form-checked-secondary {
    &:before {
      color: $secondary;
    }
  }
  &.form-checked-warning {
    &:before {
      color: $warning;
    }
  }
  &.form-checked-info {
    &:before {
      color: $info;
    }
  }
  &.form-checked-success {
    &:before {
      color: $success;
    }
  }
  &.form-checked-danger {
    &:before {
      color: $danger;
    }
  }
  &.form-checked-light {
    &:before {
      color: $light;
    }
  }
  &.form-checked-dark {
    &:before {
      color: $dark;
    }
  }
}
.form-checked-outline:checked[type="radio"] {
  background-image: none;
  position: relative;
  background-color: transparent;
  &:before {
    content: "\F309";
    font-family: bootstrap-icons !important;
    position: absolute;
    color: $primary;
    width: 0.625rem;
    height: 0.625rem;
    inset-block-start: -1.13rem;
    inset-inline-start: -0.6rem;
    font-size: 2rem;
  }
  &.form-checked-secondary {
    &:before {
      color: $secondary;
    }
  }
  &.form-checked-warning {
    &:before {
      color: $warning;
    }
  }
  &.form-checked-info {
    &:before {
      color: $info;
    }
  }
  &.form-checked-success {
    &:before {
      color: $success;
    }
  }
  &.form-checked-danger {
    &:before {
      color: $danger;
    }
  }
  &.form-checked-light {
    &:before {
      color: $light;
    }
  }
  &.form-checked-dark {
    &:before {
      color: $dark;
    }
  }
}
fieldset:disabled .btn {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
.form-select:focus {
  border-color: rgba(var($primary), 0.5);
  outline: 0;
  box-shadow: none;
}
.btn-check:focus + .btn,
.btn:focus {
  background-color: none;
  border-color: none;
  outline: 0;
}
.btn-check:active + .btn:focus,
.btn-check:checked + .btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  box-shadow: none;
}
.form-control,
.form-select {
    &:disabled {
      background-color: $light;
      color: $text-color;
  }
}
.form-control-plaintext {
  color: $text-color;
}
.form-control::-webkit-file-upload-button {
  color: $text-color;
  background-color: $default-background;
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: $default-background;
}
.form-control {
  &:focus {
      color: $gray-900;
    &::-webkit-input-placeholder,
    &::placeholder {
      color: $gray-400;
      padding-inline-start: 0.3rem;
      transition: padding-inline-start 0.1s ease-in;
    }
  }
  &::-webkit-input-placeholder {
    color: $gray-400;
    padding-inline-start: 0px;
    transition: padding-inline-start 0.1s ease-in;
  }
  &:-moz-placeholder {
    color: $gray-400;
  }
  &::-moz-placeholder {
    color: $gray-400;
  }
  &:-ms-input-placeholder {
    color: $gray-400;
  }
}
.form-control-sm {
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
  height: auto;
}
.form-control-lg {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.form-check-md {
  .form-check-input {
    width: 1.15rem;
    height: 1.15rem;
  }

  &.form-switch {
    .form-check-input {
      width: 2.25rem;
    }
  }

  label {
    margin-inline-start: 0.5rem;
    font-size: 0.95rem;
    margin-block-start: 3px;
  }
}
.form-check-lg {
  .form-check-input {
    width: 1.35rem;
    height: 1.35rem;
  }

  &.form-switch {
    .form-check-input {
      width: 2.5rem;
    }
  }

  label {
    margin-inline-start: 0.5rem;
    font-size: 1rem;
    margin-block-start: 3px;
  }
}
.label-primary {
  background: $primary;
  color: $white;
}
.label-secondary {
  background: $secondary;
  color: $white;
}
.label-warning {
  background: $warning;
  color: $white;
}
.label-info {
  background: $info;
  color: $white;
}
.label-success {
  background: $success;
  color: $white;
}
.label-danger {
  background: $danger;
  color: $white;
}
.label-light {
  background: $light;
  color: $white;
}
.label-dark {
  background: $dark;
  color: $white;
}
.form-floating > {
  .form-control-plaintext,
  .form-control {
    &::placeholder {
      color: transparent !important;
    }
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem - 4px);
}
.form-floating {
  &.floating-primary {
    label {
      color: $primary;
      opacity: 1;
    }

    input {
      border: 1px solid $primary;
    }
    & > .form-control:focus ~ label {
      color: $primary !important;
    }
  }

  &.floating-secondary {
    label {
      color: $secondary;
      opacity: 1;
    }

    input {
      border: 1px solid $secondary;
    }
    & > .form-control:focus ~ label {
      color: $secondary !important;
    }
  }

  &.floating-warning {
    label {
      color: $warning;
      opacity: 1;
    }

    input {
      border: 1px solid $warning;
    }
    & > .form-control:focus ~ label {
      color: $warning !important;
    }
  }

  &.floating-info {
    label {
      color: $info;
      opacity: 1;
    }

    input {
      border: 1px solid $info;
    }
    & > .form-control:focus ~ label {
      color: $info !important;
    }
  }

  &.floating-success {
    label {
      color: $success;
      opacity: 1;
    }

    input {
      border: 1px solid $success;
    }
    & > .form-control:focus ~ label {
      color: $success !important;
    }
  }

  &.floating-danger {
    label {
      color: $danger;
      opacity: 1;
    }

    input {
      border: 1px solid $danger;
    }
    & > .form-control:focus ~ label {
      color: $danger !important;
    }
  }
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: $white;
  border-radius: $border-radius;
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: $success;
  box-shadow: none;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: $success;
}
.form-control-color {
  width: 1.75rem;
  height: 1.75rem;
  overflow: hidden;
  padding: 0;
}
.bootstrap-tagsinput {
  border-color: $input-border;
  color: $text-color;
  background-color: $form-control-bg;
  font-size: $font-size-15;
  font-weight: $font-weight-normal;
  border-radius: 5px;
  padding: 0.45rem 0.625rem;
  height: 38px;
  box-shadow: none;
  overflow-y: auto;
  width: 100%;
  .tag {
    background: $light;
    border-radius: 5px;
    padding: 0 5px;
    font-size: $font-size-14;
    color: $gray-700;
    display: inline-flex;
    align-items: center;
    span {
        &::after {
            color: $gray-900;
        }
    }
    [data-role="remove"]:hover {
      box-shadow: none;
      &:active {
        box-shadow: none; 
      }
    }
}
}
[dir="rtl"] {
  [type="email"],
  [type="number"],
  [type="tel"],
  [type="url"] {
    direction: rtl;
  }
}
.form-select.is-invalid:not([multiple]):not([size]) {
  padding-right: 1.125rem;
  background-size: 16px 28px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
input[type="range"]::-webkit-slider-runnable-track {
  background-color: $light;
}
[dir="rtl"] {
  .form-checked-outline:checked[type="checkbox"]:before {
    inset-inline-start: 1px;
  }
}
.form-check {
  min-height: inherit !important;
}
.col-form-label-lg,
.col-form-label-sm,
.col-form-label {
  font-weight: $font-weight-semibold;
}
.col-form-label {
    font-size: $font-size-15;
    font-weight: $font-weight-medium;
    color: $title-color;
}
.form-label {
  font-size: $font-size-13;
  font-weight: 500;
  color: $title-color;
  margin-bottom: 0.3rem !important;
}
[data-theme-mode="dark"] {
  input {
    &[type="week"],
    &[type="month"],
    &[type="date"],
    &[type="datetime-local"],
    &[type="time"] {
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
  }
}
[dir="rtl"] {
  input {
    &[type="week"],
    &[type="month"],
    &[type="date"],
    &[type="datetime-local"],
    &[type="time"] {
      text-align: end;
    }
  }
}
.input-group-text {
  border-color: $light-900;
}
        /**
Form help
 */
 .form-help {
  display: inline-flex;
  font-weight: $font-weight-bold;
  align-items: center;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;
  font-size: .75rem;
  color: $white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  background: $secondary;
  border-radius: 4px;

  &:hover,
  &[aria-describedby] {
    color: $white;
    background: $primary;
  }
}
 .input-icon {
  position: relative;  
  .form-control:not(:last-child),
  .form-select:not(:last-child) {
    padding-right: 2.5rem;
  }
}


/* Input Group */

.input-group-link {
  font-size: $font-size-12;
}
.input-group-flat {
  &:focus-within {
    border-radius: $border-radius;

    .form-control,
    .input-group-text {
      border-color: $border-color !important;
    }
  }

  .form-control {
    &:focus {
      box-shadow: none;
    }

    &:not(:last-child) {
      border-right: 0;
    }

    &:not(:first-child) {
      border-left: 0;
    }
  }

  .input-group-text {
    &:first-child {
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
    }
  }
}
.custom-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: $font-size-15;
  color: $gray-700;
  text-transform: capitalize;
  input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
          ~ .checkmark {
              border-color: $primary;
              background: $primary;
              &::after {
                  opacity: 1;
                  visibility: visible;
              }
          }
      }
  }
  .checkmark {
      width: 20px;
      height: 20px;
      border: 1px solid $light-900;
      border-radius: 50%;
      -webkit-transition: all 0.3s;
      -ms-transition: all 0.3s;
      transition: all 0.3s;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      &::after {
          content: "";
          width: 8px;
          height: 8px;
          background-color: $white;
          opacity: 0;
          visibility: hidden;
          border-radius: 50%;
          -webkit-transition: all 0.3s;
          -ms-transition: all 0.3s;
          transition: all 0.3s;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          
      }
  }
} 
.check-radio-group {
  flex-wrap: wrap;
  row-gap: 10px;
  .custom-radio {
      margin-right: 15px;
  }
}
.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.checktoggle {
  background-color: rgba(115, 103, 240, 0.2);
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 46px;
}
.status-toggle.modal-status label {
  height: 16px;
  width: 30px;
  background-color: $white;
  border: 1px solid $light-900;
  margin-bottom: 0;
}
.check:checked + .checktoggle {
  background-color: $white;
}
.checktoggle:after {
  content: " ";
  display: block;
  transform: translate(2px, -50%);
  width: 10px;
  height: 10px;
  background-color: $gray-300;
  transition: left 300ms ease, transform 300ms ease;
  border-radius: 50%;
  transform: translate(2px, -50%);
  -webkit-transform: translate(4px, -50%);
  -ms-transform: translate(2px, -50%);
  position: absolute;
  top: 50%;
  left: 0;
}
.check:checked + .checktoggle:after {
  background-color: $primary;
  left: 100%;
  transform: translate(calc(-90% - 4px), -50%);
}

.input-icon-addon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  color: $gray-700;
  pointer-events: none;
  font-size: 1.2em;

  &:last-child {
    right: 0;
    left: auto;
  }
  .spinner-border-sm {
    border-width: 1px;
  }
}
.input-icon-start {
  .form-control {
      padding-left: 28px;
  }
}
.icon-addon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  color: $gray-700;
  pointer-events: none;
  font-size: 1.2em;
}
.form-wrap {
    .form-wrap-icon {
        position: relative;
        i {
            font-size: $font-size-18;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }
    }
}
.toggle-password {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: $gray-900;
    font-size: $font-size-18;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    @include rounded(50%);
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    right: 10px;
    &:hover{
        color: $primary;
    }
}
.pass-group {
    position: relative;
}
.toggle-passwords,.toggle-passworda {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: $gray-900;
    font-size: $font-size-18;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    @include rounded(50%);
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    right: 10px;
    &:hover{
        color:$primary;
    }
}
.form-wrap-checkbox {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    .check {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
        color: $gray-900;
        margin-bottom: 0;
        input {
            display: none;
            &:checked + .box {
                background: $secondary;
                &:after {
                    top: 0;
                }
            }
        }
        .box {
            width: 20px;
            height: 20px;
            display: block;
            margin-right: 8px;
            transition: all 1.1s cubic-bezier(.19,1,.22,1);
            background: $light-200;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            border: 1px solid $light-900;
            border-radius: $border-radius-lg;
            &:after {
                width: 12px;
                height: 6px;
                content: "";
                position: absolute;
                border-left: 2px solid;
                border-bottom: 2px solid;
                border-color: $white;
                transform: rotate(-45deg) translate3d(0, 0, 0);
                transform-origin: center center;
                transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
                left: 0;
                right: 0;
                top: 200%;
                bottom: 3px;
                margin: auto;
            }
        }
    }
}
.form-sort {
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-left: 35px;
    }
    span.select2-container.select2-container--default.select2-container--open {
        z-index: 99;
    }
}
.form-sort {
    position: relative;
    width: 149px;
    height: 40px;
    .info-img {
        position: absolute;
        top: 52%;
        z-index: 100;
        width: 16px;
        height: 16px;
        left: 0;
        margin-left: 10px;
        color: $text-color;
        transform: translateY(-50%);
        line-height: normal;
    }
    @include respond-below(sm) {
        margin-top: 24px;
    }
}