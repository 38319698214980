.accordion-button {
    background-color: $white;
    color: $text-color;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    font-weight: $font-weight-medium;
    &:not(.collapsed) {
        color: $text-color;
        background-color: $default-background;
        box-shadow: none;
    }
    &:focus {
        border-color: rgba($primary, 0.3);
        box-shadow: none;
    }
    &:after,&:not(.collapsed)::after {
        background-image: none;
    }
    &:after {
        content: "\f078";
        font-family: $font-awesome;
        font-size: $font-size-12;
        line-height: 1;
        background-color: #E9EFFC !important;;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3.125rem;
        color: $text-color;
    }
}  
.accordion-body {
    padding: 1rem;
    font-size: 0.8125rem;
    color: $text-muted;
}
.accordion-item {
    color: $text-color;
    background-color: $white;
    border: 1px solid $light-900;
}
.accordions-items-seperate {
    .accordion-item:not(:first-of-type) {
        margin-block-start: 1rem;
    }
    .accordion-item {
        border-block-start: 1px solid $light-900;
        border-radius: $border-radius;
        overflow: hidden;
    }
}
.accordion-item:last-of-type {
    border-bottom-right-radius: 0.35rem;
    border-bottom-left-radius: 0.35rem;
}
.accordion-item:first-of-type {
    border-top-left-radius: 0.35rem;
    border-top-right-radius: 0.35rem;
}
.accordion {
    &.accordion-primary {
        .accordion-button {
            background-color: rgba($primary, 0.1);
            color: $primary;
            &:after { 
                background-color: $primary;
                color: $white;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-secondary {
        .accordion-button {
            background-color: rgba($secondary,0.1);
            color: $secondary;
            &:after { 
                background-color: $secondary;
                color: $white;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-success {
        .accordion-button {
            background-color: rgba($success,0.1);
            color: $success;
            &:after { 
                background-color: $success;
                color: $white;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-danger {
        .accordion-button {
            background-color: rgba($danger,0.1);
            color: $danger;
            &:after { 
                background-color: $danger;
                color: $white;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-warning {
        .accordion-button {
            background-color: rgba($warning,0.1);
            color: $warning;
            &:after { 
                background-color: $warning;
                color: $white;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-info {
        .accordion-button {
            background-color: rgba($info,0.1);
            color: $info;
            &:after { 
                background-color: $info;
                color: $white;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
}
.accordion {
    &.accordion-solid-primary {
        .accordion-button {
            background-color: $primary;
            color: $white;
            &:after { 
                background-color: $white;
                color: $primary;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-solid-secondary {
        .accordion-button {
            background-color: $secondary;
            color: $white;
            &:after { 
                background-color: $white;
                color: $secondary;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-solid-success {
        .accordion-button {
            background-color: $success;
            color: $white;
            &:after { 
                background-color: $white;
                color: $success;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-solid-danger {
        .accordion-button {
            background-color: $danger;
            color: $white;
            &:after { 
                background-color: $white;
                color: $danger;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-solid-warning {
        .accordion-button {
            background-color: $warning;
            color: $white;
            &:after { 
                background-color: $white;
                color: $warning;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
    &.accordion-solid-info {
        .accordion-button {
            background-color: $info;
            color: $white;
            &:after { 
                background-color: $white;
                color: $info;
            }
            &.collapsed {
                background-color: $white;
                color: $text-color;
                &:after { 
                    background-color: $default-background;
                    color: $text-color;
                }
            }
        }
    }
}
.accordion {
    &.accordion-border-primary {
        .accordion-item {
            border: 1px solid $primary;
        }
        .accordion-button {
            background-color: $white;
            color: $primary;
            border-block-end: 0px;
            &:after { 
                background-color: $white;
                color: $primary;
                border: 1px solid $primary;
            }
            &:not(.collapsed) {
                border-block-end: 1px solid $primary;
            }
        }
    }
    &.accordion-border-secondary {
        .accordion-item {
            border: 1px solid $secondary;
        }
        .accordion-button {
            background-color: $white;
            color: $secondary;
            border-block-end: 0px;
            &:after { 
                background-color: $white;
                color: $secondary;
                border: 1px solid $secondary;
            }
            &:not(.collapsed) {
                border-block-end: 1px solid $secondary;
            }
        }
    }
    &.accordion-border-success {
        .accordion-item {
            border: 1px solid $success;
        }
        .accordion-button {
            background-color: $white;
            color: $success;
            border-block-end: 0px;
            &:after { 
                background-color: $white;
                color: $success;
                border: 1px solid $success;
            }
            &:not(.collapsed) {
                border-block-end: 1px solid $success;
            }
        }
    }
    &.accordion-border-danger {
        .accordion-item {
            border: 1px solid $danger;
        }
        .accordion-button {
            background-color: $white;
            color: $danger;
            border-block-end: 0px;
            &:after { 
                background-color: $white;
                color: $danger;
                border: 1px solid $danger;
            }
            &:not(.collapsed) {
                border-block-end: 1px solid $danger;
            }
        }
    }
    &.accordion-border-warning {
        .accordion-item {
            border: 1px solid $warning;
        }
        .accordion-button {
            background-color: $white;
            color: $warning;
            border-block-end: 0px;
            &:after { 
                background-color: $white;
                color: $warning;
                border: 1px solid $warning;
            }
            &:not(.collapsed) {
                border-block-end: 1px solid $warning;
            }
        }
    }
    &.accordion-border-info {
        .accordion-item {
            border: 1px solid $info;
        }
        .accordion-button {
            background-color: $white;
            color: $info;
            border-block-end: 0px;
            &:after { 
                background-color: $white;
                color: $info;
                border: 1px solid $info;
            }
            &:not(.collapsed) {
                border-block-end: 1px solid $info;
            }
        }
    }
}
.accordion {
    &.accordionicon-none {
        .accordion-button:after {
            display: none;
        }
    }
}
.accordion {
    &.accordionicon-left {
        .accordion-button {
            padding-inline-start: 2.5rem;
            &:after {
                position: absolute;
                inset-inline-start: 0.625rem;
            }
        }
    }
}
.accordion {
    &.accordion-customicon1 {
        .accordion-button {
            &:after {
                content: "\2b";
                font-family: $font-awesome;
                background-color: $default-background;
            }
            &:not(.collapsed)::after {
                content: "\f068";
				font-family: $font-awesome;
                background-color: $white;
            }
        }
    }
}
.customized-accordion {
    .accordion-item{
        &.custom-accordion-primary {
            .accordion-button {
                background-color: rgba($primary, 0.1);
                border-inline-start: 0.25rem solid rgba($primary, 0.6);
                &:after {
                    background-color: rgba($primary, 0.6);
                    color: $white;
                }
            }
            .accordion-body {
                background-color: rgba($primary, 0.1);
                padding-block-start: 0;
                border-inline-start: 0.25rem solid rgba($primary, 0.6);
            }
        }
        &.custom-accordion-secondary {
            .accordion-button {
                background-color: rgba($secondary,0.1);
                border-inline-start: 0.25rem solid rgba($secondary,0.6);
                &:after {
                    background-color: rgba($secondary,0.6);
                    color: $white;
                }
            }
            .accordion-body {
                background-color: rgba($secondary,0.1);
                padding-block-start: 0;
                border-inline-start: 0.25rem solid rgba($secondary,0.6);
            }
        }
        &.custom-accordion-warning {
            .accordion-button {
                background-color: rgba($warning,0.1);
                border-inline-start: 0.25rem solid rgba($warning,0.6);
                &:after {
                    background-color: rgba($warning,0.6);
                    color: $white;
                }
            }
            .accordion-body {
                background-color: rgba($warning,0.1);
                padding-block-start: 0;
                border-inline-start: 0.25rem solid rgba($warning,0.6);
            }
        }
        &.custom-accordion-info {
            .accordion-button {
                background-color: rgba($info,0.1);
                border-inline-start: 0.25rem solid rgba($info,0.6);
                &:after {
                    background-color: rgba($info,0.6);
                    color: $white;
                }
            }
            .accordion-body {
                background-color: rgba($info,0.1);
                padding-block-start: 0;
                border-inline-start: 0.25rem solid rgba($info,0.6);
            }
        }
        &.custom-accordion-success {
            .accordion-button {
                background-color: rgba($success,0.1);
                border-inline-start: 0.25rem solid rgba($success,0.6);
                &:after {
                    background-color: rgba($success,0.6);
                    color: $white;
                }
            }
            .accordion-body {
                background-color: rgba($success,0.1);
                padding-block-start: 0;
                border-inline-start: 0.25rem solid rgba($success,0.6);
            }
        }
        &.custom-accordion-danger {
            .accordion-button {
                background-color: rgba($danger,0.1);
                border-inline-start: 0.25rem solid rgba($danger,0.6);
                &:after {
                    background-color: rgba($danger,0.6);
                    color: $white;
                }
            }
            .accordion-body {
                background-color: rgba($danger,0.1);
                padding-block-start: 0;
                border-inline-start: 0.25rem solid rgba($danger,0.6);
            }
        }
    }
}