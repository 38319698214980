.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 25px;
  }
  
  .grid-container-singleView {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px;
  }
  
  
  .student-box {
    position: relative;
  }
  
  .student-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  
  .datail_cont {
    width: 100%;
    max-width: 50%;
  }
  
  .hidden-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    /* Hide checkbox */
    cursor: pointer;
    /* Make the box clickable */
  }
  
  .hidden-checkbox:checked+.student-label {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    /* Gray shadow */
    transform: scale(1.05);
    border: 1px solid #ccc;
    /* Optional border when selected */
  }
  
  .student-label {
    display: block;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    background-color: #fff;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    cursor: pointer;
  }
  
  .student-img {
    width: 100%;
    max-width: 100px;
    height: 80px;
    border-radius: 5px;
    object-fit: cover;
    /* border-radius: 50%; */
  }
  
  .text-content {
    font-size: small;
  }
  
  .text-date {
    font-size: smaller;
  }
  
  .student-img-singleView {
    width: 90%;
    height: 50%;
    object-fit: cover;
  
    border-radius: 10px;
  }
  
  .point {
    padding: 5px 40px;
    margin: 0 5px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border-radius: 5px;
  
  }
  
  
  .scores {
    display: flex;
    margin-top: 10px;
  }
  
  .score {
    padding: 5px 10px;
    margin: 0 5px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    max-width: 50%;
    border-radius: 5px;
  
  }
  
  .score.green {
    background-color: #28a745;
    color: white;
  }
  
  .score.red {
    background-color: #dc3545;
    color: white;
  }
  
  
  .w-60 {
    width: 60%;
  }
  
  .w-40 {
    width: 40%;
  }